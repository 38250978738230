import React from "react"
import PropTypes from 'prop-types';
import { Link } from "gatsby"

import { Navbar, Nav } from "react-bootstrap"


class CustomNavbar extends React.Component {
  render() {
    return (
      <Navbar expand="sm" variant="dark">
        <Navbar.Brand as={Link} to="/"><img src={"/images/logo-accelerate.svg"} alt=""/></Navbar.Brand>
        <Navbar.Toggle className="navbar-toggle" aria-controls="navbarsAccelerate" aria-expanded="false" aria-label="Toggle navigation" data-toggle="collapse" >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar top-bar"></span>
          <span className="icon-bar middle-bar"></span>
          <span className="icon-bar bottom-bar"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarsAccelerate">
          <Nav className="ml-auto" activeKey={this.props.pageInfo && this.props.pageInfo.pageName}>
            <Nav.Link as={Link} to="/about" eventKey="about">About</Nav.Link>
            <Nav.Link as={Link} to="/team" eventKey="team">Team</Nav.Link>
            <Nav.Link as={Link} to="/pitch" eventKey="pitch">Pitch</Nav.Link>
            <Nav.Link as={Link} to="/contact" eventKey="contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>

      </Navbar>
    )
  }
}

CustomNavbar.propTypes = {
  pageInfo: PropTypes.object
};

export default CustomNavbar;
