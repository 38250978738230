/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { Container } from "react-bootstrap"
import Navbar from "./navBar"
import PropTypes from "prop-types";
import Footer from "./footer";

// eslint-disable-next-line react/prop-types
const Layout = ({ children, pageInfo }) => (
  <Container fluid={true} className='main-wrapper'>
    <Navbar pageInfo={pageInfo} />
    <section className="main-content">
      {children}
    </section>
    <Footer/>
  </Container>
)

Layout.propTypes = {
  pageInfo: PropTypes.object,
}

export default Layout
