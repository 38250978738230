import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col } from "react-bootstrap"
import { faFacebookF, faYoutube, faTwitter} from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component {

  render() {
    const year = (new Date()).getFullYear();
    /**
     *             <Col lg={2} className="text-center py-4 privacy-col"><Link to="/privacy-policy">Privacy Policy</Link></Col>
     */
    return (
      <footer>
          <Row className="justify-content-between footer-content">
            <Col lg={4} className="py-4 copyright-col">&copy; {year} Accelerate Games, Inc.</Col>
            <Col lg={4} className="py-4 follow-us-col text-center"><span className='follow-us'>Follow us </span>
              <span>
                <a href="https://www.facebook.com/AccelerateGames/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} className={'brandIcon'}/>
                </a>
              </span>
              <span>
                <a href="https://twitter.com/gamesAccelerate" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} className={'brandIcon'}/>
                </a>
              </span>
              <span>
                <a href="https://www.youtube.com/channel/UCSc9ABkRlpCH2s5N4_D-Nig" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faYoutube} className={'brandIcon'}/>
                </a>
              </span>
            </Col>
            <Col lg={4} className="subscribe-col">
              <div className="subscribe-widget">
                <label>Subscribe For Updates</label>
                <form
                  action="https://games.us4.list-manage.com/subscribe/post?u=3e9053fabf65c70e19ae67bf6&amp;id=24a4f5238b"
                  method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                  className="validate form-inline" target="_blank" noValidate>
                  <div className="form-group mx-sm-2 mb-2">
                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL"
                           placeholder="Your email"/>
                  </div>
                  <button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"
                          className="btn btn-primary mb-2"><img src="/images/send.svg" alt="subscribe"/></button>
                  <div id="mce-responses" className="clear" style={{marginLeft: '16px'}}>
                    <div className="response" id="mce-error-response" style={{display: 'none'}}/>
                    <div className="response" id="mce-success-response" style={{display: 'none'}}/>
                  </div>
                  <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                    <input type="text" name="b_3e9053fabf65c70e19ae67bf6_24a4f5238b" tabIndex="-1" value=""/>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
      </footer>
    )
  }
}



export default Footer;
